import axios from 'axios';

const wallet = axios.create({
    baseURL: process.env.VUE_APP_BASE_WALLET_URL,
});

wallet.defaults.headers.post['Content-Type'] = 'application/json';
wallet.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

wallet.defaults.method = "post";

export default wallet;