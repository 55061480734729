<template>

  <div class="body">

    <div id="app">

      <div v-show="current_page !== 'casinogames'" class="mpesaBanner text-center" style="position: fixed" @click="showDeposit">

        <div class="paybill">M-PESA Paybill: 9644555</div>

      </div>

      <nav v-show="current_page !== 'casinogames'" class="navbar navbar-dark" style="border-radius: 0px; margin-bottom: 1px; text-align: center;">

        <div class="row w-100">

          <div class="col-2 col-md-1 d-flex justify-content-center">

            <a  @click="showMenu" style="margin-top: 10px;">
              <img src="/assets/images/icons/menu.svg" width="20" style="color: white" />
              <div class="white-txt bold text-center">A - Z</div>
            </a>

          </div>

          <div class="col-2 col-md-1 d-flex justify-content-center">

            <router-link to="/search" v-if="current_page !== 'search'" style="margin-top: 10px;">
              <img src="/assets/images/icons/search.svg" width="20" style="color: white" />
              <div class="white-txt bold text-center">Search</div>
            </router-link>

            <a @click="goBack()" v-else style="margin-top: 10px;">
              <img src="/assets/images/icons/back.svg" width="20" style="color: white" />
              <div class="white-txt bold text-center">Back</div>
            </a>

          </div>

          <div class="col-4 col-md-8 d-flex justify-content-center">
            <router-link class="navbar-brand" to="/" style="color: white;font-weight: 600;">
              <lazy-image src="/assets/images/logo.png?y" alt="Logo" style="width: 100px;"/>
            </router-link>
          </div>

          <div class="col-2 col-md-1 d-flex justify-content-start" style="">

              <router-link
                  v-if="!profile"
                  v-show="current_page !== 'join' "
                  to="/join"
                  style="margin-top: 10px;"
                  class="menu-btn login">
                <span>Login</span>
              </router-link>

              <router-link v-else to="/my-bets" style="margin-top: 10px;padding-right: 10px;">
                <img src="/assets/images/icons/my-bets.svg" v-if="activeBets > 0 " width="20" style="color: yellow" />
                <img src="/assets/images/icons/my-bets.svg" v-else width="20" style="color: white" />
                <div class="white-txt bold" style="text-align: center;">My Bets</div>
              </router-link>

          </div>

          <div class="col-2 col-md-1 d-flex justify-content-end" style="">

            <router-link
                v-if="!profile"
                v-show="current_page !== 'join' "
                to="/join"
                style="margin-top: 10px;"
                class="menu-btn join">
              <span>Join</span>
            </router-link>

            <router-link v-else to="/setting" style="margin-top: 10px;padding-right: 10px">
              <img src="/assets/images/icons/profile.svg" width="20" style="color: white" />
              <div class="white-txt bold text-center">Ksh. <span v-text="bal"></span></div>
            </router-link>

          </div>

        </div>

      </nav>

      <div id="menu-modal" class="faso-menu-modal" style="z-index: 999;">

        <!-- Modal content -->
        <div class="faso-menu-modal-content">

          <nav class="navbar-dark faso-menu-modal-header">

            <div class="row w-100">

              <div class="col-6 d-flex justify-content-start" @click="goTo('Home')">
                <a class="navbar-brand">
                  <lazy-image src="/assets/images/logo.png?x" alt="Your best sports betting site | fast play fast win" style="width: 150px;"/>
                </a>
              </div>

              <div class="col-6 d-flex justify-content-end">

                <div v-if="!profile">
                  <a @click="goTo('Login')" class="btn btn-primary btn-sm black-txt"
                     style="background-color: var(--secondary) !important;">
                    <b>Login/Register</b>
                  </a>
                </div>

                <div v-else>

                  <div style="margin: 0 -1em;" class="reg-login">

                    <a class="btn btn-primary btn-sm black-txt" @click="goTo('Setting')"
                       style="background-color: var(--secondary) !important;position: relative;top: 1em;height: 2.5em !important;">
                      <strong>DEPOSIT</strong>
                    </a>

                  </div>

                </div>

              </div>

            </div>

          </nav>

          <div class="d-flex justify-content-between main-menu w-100">

            <!-- Home -->
            <a class="nav-item">
              <!-- Image -->
              <div class="nav-icon" @click="goTo('Home')">
                <HomeIcon :size="iconSize" fill-color="white"></HomeIcon>
                <span class="nav-text white-txt bold"
                      style="font-size: 0.6875em !important; margin-top: -10px">Home</span>
              </div>
            </a>

            <!-- Live -->
            <router-link class="nav-item" to="/live" >
              <div class="nav-icon">
                <img v-bind:src="getURL('/assets/images/live_old.svg')" alt="tapabet live- best sports betting site" height="20">
                <div class="nav-text white-txt bold" style="font-size: 0.6875em !important; text-align: center">Live</div>
              </div>
            </router-link>

            <!-- Soccer -->
            <a class="nav-item menu-active" @click="goTo('Home')">
              <!-- Image -->
              <div class="nav-icon">
                <SoccerIcon :size="iconSize" fill-color="white"></SoccerIcon>
                <span class="nav-text white-txt bold"
                      style="font-size: 0.6875em !important;margin-top: -10px">Soccer</span>
              </div>
            </a>

          </div>

          <div class="countries">

            <div v-if="profile" class="sports-menu">
              <person-icon v-bind:size="iconSize" style="padding-top: 1px"></person-icon>
              {{ full_name }}
            </div>

            <div v-if="profile" class=" countries-item trend-item menu-text"
                 style="padding-left: 1em;padding-right: 1em;">

              <div class="trend-text1">
                <div class="small amount-type">Balance</div>
                <div class="amount">KES. {{ profile.b1 }}</div>
              </div>
              <div class="trend-text1 vertical-line"></div>

              <div class="trend-text1">
                <div class="small amount-type" style="text-align: right">Bonus</div>
                <div class="amount">KES. {{ profile.b12 }}</div>
              </div>

            </div>

            <div class=" countries-item trend-item menu-text" style="display: none">
              <a style="display: flex;width: 100%;justify-content: space-between;" href="apps.html">
                <div class="trend-text1" style="font-size: 1em">
                  App <small class="label label-faso">NEW</small>
                </div>
                <div class="trend-text1">
                  <span style="position: relative;top: 6px;">
                    <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                  </span>
                </div>
              </a>
            </div>


            <div class=" countries-item trend-item menu-text">
              <a style="display: flex;width: 100%;justify-content: space-between;"  @click="goTo('Jackpot')">
                <div class="trend-text1" style="font-size: 1em">
                  Daily Jackpot <small class="label label-faso">NEW</small>
                </div>
                <div class="trend-text1">
                  <span style="position: relative;top: 6px;">
                    <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                  </span>
                </div>
              </a>
            </div>

            <div v-if="profile" class=" countries-item trend-item menu-text" @click="goTo('Bets')">

              <div class="trend-text1" style="font-size: 1em">
                My Bets
              </div>

              <div class="trend-text1">
                <span style="position: relative;top: 6px;">
                    <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                  </span>
              </div>

            </div>

            <div v-if="profile" class=" countries-item trend-item menu-text" @click="goTo('Setting')">
              <div class="trend-text1" style="font-size: 1em">
                Withdraw Winnings
              </div>
              <div class="trend-text1">
                <span style="position: relative;top: 6px;">
                    <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                  </span>
              </div>
            </div>

            <a class=" countries-item trend-item menu-text" href="https://ls.sir.sportradar.com/fasobet" target="_blank">

              <div class="trend-text1" style="font-size: 1em">
                Live Score
              </div>

              <div class="">
                <span style="position: relative;top: 6px;">
                    <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                  </span>
              </div>

            </a>

            <div class=" countries-item trend-item menu-text">
              <a style="display: flex;width: 100%;justify-content: space-between;"  @click="goTo('Outright')">
                <div class="trend-text1" style="font-size: 1em">
                  Outrights
                </div>
                <div class="">
                  <span style="position: relative;top: 6px;">
                      <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                    </span>
                </div>
              </a>
            </div>

            <div class=" countries-item trend-item menu-text">
              <a style="display: flex;width: 100%;justify-content: space-between;"  @click="goTo('Booklet')">
                <div class="trend-text1" style="font-size: 1em">
                  Booklet
                </div>
                <div class="trend-text1">
                  <span style="position: relative;top: 6px;">
                    <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                  </span>
                </div>
              </a>
            </div>

            <div class="sports-menu">
              All Sports
            </div>

            <div v-for="(c,index) in sports" v-bind:key="getKey(index)" class="countries-item">

              <div class="trend-item" @click="setSport(c)">

                <div class="trend-text1">
                  <lazy-image
                      class="lozad"
                      v-bind:alt="c.sport_name"
                      v-bind:src="getSportIcon(c.sport_id)"
                      onerror="javascript:this.src='/assets/sport-icons/1.png'"
                      style="width: 15px"/>
                  {{ c.sport_name }}
                </div>

                <div class="trend-text1">
                  {{ c.games }}
                  <span style="position: relative;top: 6px;">
                    <arrow-right-icon style="height: 1em;"></arrow-right-icon>
                  </span>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>
      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>
      <div id="deposit-modal" class="deposit-modal">

        <div id="status"></div>

        <!-- Modal content -->
        <div class="deposit-modal-content" id="depositmobile">

          <div class="deposit-modal-header d-flex justify-content-between" style="background-color: var(--primary);">

            <div class="d-flex justify-content-start">
              <div style="font-size: var(--font-large); color: var(--white);">Deposit</div>
            </div>

            <div class="d-flex justify-content-end">
              <div style="font-size: var(--font-largest);color: var(--white);" id="deposit-modal-close">&times;</div>
            </div>

          </div>


          <div class="deposit-modal-body" style="/*height: 20em;*/overflow-y: auto;">
            <div class="text-center pt-3 blue-txt bold-txt">DEPOSIT</div>
            <div class="text-center blue-txt" v-text="message"></div>
            <div class="form">

              <div class="form-group" style="margin-bottom: -1em;">

                <div style="display: flex;justify-content: space-around;">

                  <div class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(33)">
                    <span class="bold">+</span> 33
                  </div>

                  <div class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(99)">
                    <span class="bold">+</span> 99
                  </div>

                  <div class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(298)">
                    <span class="bold">+</span> 298
                  </div>

                  <div class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(499)">
                    <span class="bold">+</span> 499
                  </div>

                  <div class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(1999)">
                    <span class="bold">+</span> 1999
                  </div>

                </div>

                <input type="number" v-model="amount" class="w-100 mt-3 mb-3 small-txt" id="amount" aria-describedby="amountHelp">

              </div>

              <!--<div  style="font-size: var(&#45;&#45;font-small); text-align: left;">Minimum Kes 5</div>-->

              <button class="btn btn-block btn-sm bg-blue" @click="deposit" v-bind:class="loading">Deposit</button>

            </div>
          </div>

          <!--<div class="" style="position: fixed;bottom: 0;width: 100%;height: 12em;max-width: 786px;">

            <div class=" faso-modal-footer d-flex flex-column pb-0" style="background-color: var(--primary);">Footer</div>

          </div>-->

        </div>

      </div>
      <span id="deposit-init" class="hidden"></span>

      <router-view></router-view>

    </div>

  </div>

</template>

<style scoped>
  .label-faso {
    padding: 1px 1px !important;
    margin: 0;
    margin-bottom: 0px;
    margin-bottom: 2px;
    background: #FE004E;
    color: #fff !important;
  }
</style>


<script>
// import jQuery from "jquery";
// const $ = jQuery;


import mqtt from "mqtt";
import wallet from "@/services/wallet";

const SoccerIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Soccer.vue');
const HomeIcon = () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Home.vue');

export default {
  name: 'app',
  components: {
    SoccerIcon,
    HomeIcon,
  },
  mounted: function () {

    var vm = this;

    vm.myProfile = vm.getProfile();

    this.initMenuModal();

    this.initDepositModal();

    this.getSports();

    vm.autoRefreshUI(vm.$vnode.tag);

    vm.reloadProfile();

    this.EventBus.$on('deposit:popup',function(payload){
      vm.amount = payload.amount;
      vm.message = payload.message;
      document.getElementById("deposit-init").click();

    });

    this.EventBus.$on('init:mqtt',function(){

      console.log('Wants Inititalize MQTT');

      if(vm.mqttClient !== false ) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referrer = document.referrer
    console.log('GOT referrer '+referrer)

    if(utm_source.length > 0 ) {

      this.setValue('utm_source',utm_source)
    }

    if(utm_medium.length > 0 ) {

      this.setValue('utm_medium',utm_medium)
    }

    if(utm_campaign.length > 0 ) {

      this.setValue('utm_campaign',utm_campaign)
    }

    //if(referrer.length > 0 ) {

      this.setValue('referrer',referrer)

   // }

  },
  computed: {

    bonus: function (){

      return this.formatCurrency(this.$store.state.bonusBalance);

    },

    bal: function() {

      return this.formatCurrency(this.$store.state.walletBalance);

    },

    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }


      var fn = this.profile.first_name;
      var mn = this.profile.middle_name;
      var ln = this.profile.last_name;
      //var ms = this.profile.msisdn;
      return fn + ' ' + mn + ' ' + ln ;

    },

    profile: function() {

      return this.getProfile();

    },

    current_page: function () {

      return this.$store.state.current_page;

    },

    sports: function () {

      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {

        if (v.sport_id > 1) {

          t.push(v);
        }

      })

      return t;
    },

    homePageFontColor: function () {

      if (this.current_page === 'home') {

        return "yellow-txt";
      }

      return "";

    },

    livePageFontColor: function () {

      if (this.current_page === 'live') {

        return "yellow-txt";
      }

      return "";

    },

    historyPageFontColor: function () {

      if (this.current_page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip
    },
    activeBets: function () {

      var p = this.getProfile();

      if(!p) {

        return 0;

      }

      return p.b

    },

    betslip_count: function () {

      if(!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },

  },
  methods: {

    livescore: function(){


    },

    isIframe: function (){

      return this.current_page === "casinogames"

    },

    initMqtt: function(){

      var vm = this;

      if(this.mqttClient !== false ) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      var client  = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST,options);

      client.on('connect', function () {

        if(vm.profile) {

          var profileTopic = 'topic/profile/'+vm.profile.t;
          client.subscribe(profileTopic, function (err) {

            if (!err) {

              console.log('subscribed to topic '+profileTopic);

            }

          });
        }

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.oddsUpdate(payload);
      })

      this.mqttClient = client;

    },

    getMarket: function (sport_id) {

      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {

      this.dismiss();
      console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },

    goTo: function (Name) {

      this.dismiss();

      Name = Name.toLowerCase();

      if (this.$store.state.current_page === Name)
        return

      this.$router.push({name: Name});
    },
    initDeposit: function () {

      this.dismiss();
      this.$router.push({name: 'deposit'});
    },
    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },
    getSports: function () {

      this.$store.dispatch("getSports");

    },

    setAmount: function (amount) {

      this.amount = amount
      this.deposit();

    },

    deposit: function () {

      var vm = this;
      this.reset();

      var p = this.getProfile();

      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({name: "login", params: {}});
        return;
      }

      if (this.amount < 1) {

        this.setError("Invalid Amount", "ENter amount atleast 1 KSH or above");
        return;

      }

      var path = '/deposit/initiate';
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = "loading";

      wallet
          .post(path, JSON.stringify(data), {
            headers: {
              "api-key": vm.getAuth(),
            },
          })
          .then((res) => {

            // console.log("Response", res);
            vm.loading = "";
            var msg = res.data.message.data;
            vm.setSuccess("Deposit Initiated", msg);
            vm.message = "Please enter amount you want to deposit";

          })
          .catch((err) => {

            console.log("Response", err);

            vm.loading = "";

            if (err.response) {

              if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();

              } else {

                vm.setError("Failed", err.response.data.message);

              }

            } else if (err.request) {

              // vm.setError(
              //     "Network Error",
              //     "Check your network connection and try again"
              // );
              // console.log(JSON.stringify(err.request));

            } else {

              // vm.setError(
              //     "Network Error",
              //     "Check your network connection and try again"
              // );
              // console.log(JSON.stringify(err));

            }
          });
    },


    initMenuModal: function () {

      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("#menu-modal")) {

          modal.style.display = "none";
        }

      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {

        if (event.target === modal) {

          modal.style.display = "none";

        }
      }

    },

    initDepositModal: function(){

      // Get the modal
      var modal = document.getElementById("deposit-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("deposit-init");

      // Get the <span> element that closes the modal
      var span = document.getElementById("deposit-modal-close");

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      }

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {

          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#deposit-modal")) {

          modal.style.display = "none";
        }

      });


    },

    showDeposit: function() {

      document.getElementById("deposit-init").click();

    },
    searchVisible: function () {

      console.log('this.current_page ==> ' + this.current_page);

      var searchVisible = this.current_page !== 'search';
      console.log('this.current_page ==> ' + this.current_page + ' searchVisible ==> ' + searchVisible);

      return searchVisible;

    }
  },
  data: function () {
    return {
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message:'Please enter amount you want to deposit',
    }
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>