<template>

    <div class="wrapper">

      <Menu></Menu>

      <Slider></Slider>

      <Boosted></Boosted>

        <Highlights></Highlights>

        <Markets></Markets>

        <Dropdown></Dropdown>

        <Games v-bind:sport="sport_id" highlights="true" v-show="current_sub_page !== 'countries' "></Games>

        <Countries v-show="current_sub_page === 'countries' "></Countries>

        <Trending class=""></Trending>

        <BottomFooter></BottomFooter>

        <bottom-navigation active-bets="0" page="home"></bottom-navigation>

      </div>

</template>

<script>
const Highlights = () => import('./Highlights.vue')
const Dropdown = () => import('./Dropdown.vue')
const Slider = () => import('./Slider.vue')
const Games = () => import('./Games.vue')
const BottomFooter = () => import('./BottomFooter.vue')
const Menu = () => import('./Menu.vue')
const Boosted = () => import('./Boosted.vue')
const BottomNavigation = () => import('./BottomNavigation.vue')
const Trending = () => import('./Trending.vue')
const Countries = () => import('./Countries1.vue');
const Markets = () => import('./Markets.vue')

export default {
  name: 'Home',
  components: {
    Menu,
    Slider,
    Highlights,
    Dropdown,
    Games,
    BottomFooter,
    BottomNavigation,
    Trending,
    Countries,
    Boosted,
    Markets
  },
  data: function (){

    return {
      sub_page: '',
      mqttClient:'',
    }

  },
  mounted() {

    this.$store.dispatch("setCurrentPage","home");
    //this.$store.dispatch("setSportID",1);
    this.$store.dispatch("setHour",-1);
    //this.$store.dispatch("setMarketID",1);
    this.$store.dispatch("setCompetitionID",0);

    // reset game filter
    //this.$store.dispatch("resetAllGames");
    this.reloadProfile();

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    }
  },
  methods: {

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  },
}
</script>