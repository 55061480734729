import Home from './components/Home'
import AppHome from './components/AppHome'
const Live = () => import('./components/Live')
const Match = () => import('./components/Match')
const Login = () => import('./components/Login')
const Reset = () => import('./components/Reset')
const Deposit = () => import('./components/Deposit')
const Setting = () => import('./components/Setting')
const Bets = () => import('./components/Bets')
const Terms = () => import('./components/Terms')
const Faq = () => import('./components/Faq')
const Freebet = () => import("@/components/Freebet");
const Search = () => import("./components/Search");
const Countries = () => import("@/components/Countries");
const Outright = () => import("@/components/outright/Index");
const Today = () => import("@/components/Today");
const Upcoming = () => import("@/components/Upcoming");
const Share = () => import("@/components/Share");
const Invite = () => import("@/components/Invite");
const Help = () => import("./components/Help");
const Responsible = () => import("./components/Responsible");
const Booklet = () => import("./components/Booklet");
const Jackpot = () => import("./components/jackpot/Jackpot");
const CasinoGames = () => import("./components/casino/CasinoGames");
const Casino = () => import("./components/casino/Casino");
const Rocketman = () => import("./components/casino/Rocketman");
const Endorphina = () => import("./components/casino/Endorphina");
const EndorphinaGames = () => import("./components/casino/EndorphinaGames");
const XGames = () => import("./components/casino/Xgames");
const Virtuals = () => import("./components/casino/Virtuals");
const LiveCasino = () => import("./components/casino/Live");
const Jetsafi = () => import("./components/casino/Jetsafi");

export const routes = [
    {
        path: '/',
        name: 'home',
        component: Countries,
        meta: {
            title: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.',
            metaTags: [
                {
                    name: 'title',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    name: 'description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:title',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'og:title',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'og:url',
                    content: 'https://tapabet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/a',
        name: 'apphome',
        component: AppHome,
        meta: {
            title: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.',
            metaTags: [
                {
                    name: 'title',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    name: 'description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:title',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'og:title',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'og:url',
                    content: 'https://tapabet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            title: 'tapabet.co.ke |  Best online sport Betting In Africa',
            metaTags: [
                {
                    name: 'title',
                    content: 'tapabet.co.ke |  Best online sport Betting In Africa'
                },
                {
                    name: 'description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:title',
                    content: 'tapabet.co.ke |  Best online sport Betting In Africa'
                },
                {
                    property: 'og:title',
                    content: 'tapabet.co.ke |  Best online sport Betting In Africa'
                },
                {
                    property: 'og:url',
                    content: 'https://tapabet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
        meta: {
            title: 'Live Sports | Best Online Sport Betting | tapabet | Fast Play Fast Win',
            metaTags: [
                {
                    name: 'title',
                    content: 'Live Sports | Best Online Sport Betting | tapabet | Fast Play Fast Win'
                },
                {
                    name: 'description',
                    content: 'Online Sports betting is a great way to make some money while enjoying the game. Get the best live betting experience from tapabet, the best online sports betting site Kenya'
                },
                {
                    name: 'keywords',
                    content: 'Live sports, bets, odds , multibet, jackpot,sport betting, best online sports betting'
                }
            ]
        }
    },
    {
        path: '/freebet',
        name: 'freebet',
        component: Freebet,
        meta: {
            title: 'tapabet Esports |Live matches | Betting',
            metaTags: [
                {
                    name: 'title',
                    content: 'tapabet Esports |Live matches | Betting'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },
    {
        path: '/today',
        name: 'today',
        component: Today,
        meta: {
            title: 'tapabet Esports |Live matches | Betting',
            metaTags: [
                {
                    name: 'title',
                    content: 'tapabet Esports |Live matches | Betting'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },
    {
        path: '/upcoming',
        name: 'upcoming',
        component: Upcoming,
        meta: {
            title: 'tapabet Esports |Live matches | Betting',
            metaTags: [
                {
                    name: 'title',
                    content: 'tapabet Esports |Live matches | Betting'
                },
                {
                    name: 'description',
                    content: 'Best in-play betting company in Kenya that allows you to take your betting skills to a whole new level.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                }
            ]
        }
    },
    {
        path: '/sport/:status/markets/:game_id/:name',
        name: 'match',
        component: Match,
    },
    {
        path: '/share/:code',
        name: 'share',
        component: Share,
    },
    {
        path: '/invite/:code',
        name: 'invite',
        component: Invite,
    },
    {
        path: '/join',
        name: 'login',
        component: Login,
        meta: {
            title: 'tapabet Account Registration|Free Welcome Bonuses| Live Bets| Multibets',
            metaTags: [
                {
                    name: 'description',
                    content: 'Looking for a place to start your online sports betting? tapabet is the place to be, best online betting platform in Africa. Sign up for a free bet worth Ksh 25!'
                },
                {
                    name: 'keywords',
                    content: 'sports betting,join, jackpot,today\'s games, this week jackpot, multibet, freebet, sign-up,live bets,online casino,betting bonus'
                }
            ]
        }
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: Deposit,
        meta: {
            title: 'Depost To Your tapabet Account',
            metaTags: [
                {
                    name: 'description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with tapabet, the betst betting company in Africa.'
                },
                {
                    name: 'keyword',
                    content: 'Deposit, tapabet, Betting, place a bet, how to bet, mobile betting, Withdrawal methods..'
                },
                {
                    property: 'og:description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with tapabet, the betst betting company in Africa.'
                },
                {
                    property: 'twitter:description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with tapabet, the betst betting company in Africa.'
                },
                {
                    property: 'twitter:title',
                    content: 'Depost To Your tapabet Account'
                },
                {
                    property: 'og:title',
                    content: 'Depost To Your tapabet Account'
                },
                {
                    property: 'og:url',
                    content: 'https://tapabet.co.ke/deposit'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/countries',
        name: 'country',
        component: Countries,
        meta: {
            title: 'Countries tapabet Is Available',
            metaTags: [
                {
                    name: 'description',
                    content: 'tapabet is available in these countries Africa hence you can play your esports or bet with tapabet in your country or in eather of these other countries.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online.'
                },
                {
                    property: 'og:description',
                    content: 'tapabet is available in these countries Africa hence you can play your esports or bet with tapabet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:description',
                    content: 'tapabet is available in these countries Africa hence you can play your esports or bet with tapabet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:title',
                    content: 'Countries tapabet Is Available'
                },
                {
                    property: 'og:title',
                    content: 'Countries tapabet Is Available'
                },
                {
                    property: 'og:url',
                    content: 'https://tapabet.co.ke/countries'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/outright',
        name: 'outright',
        component: Outright,
        meta: {
            title: 'Outrights tapabet Is Available',
            metaTags: [
                {
                    name: 'description',
                    content: 'tapabet is available in these countries Africa hence you can play your esports or bet with tapabet in your country or in eather of these other countries.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online.'
                },
                {
                    property: 'og:description',
                    content: 'tapabet is available in these countries Africa hence you can play your esports or bet with tapabet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:description',
                    content: 'tapabet is available in these countries Africa hence you can play your esports or bet with tapabet in your country or in eather of these other countries.'
                },
                {
                    property: 'twitter:title',
                    content: 'Countries tapabet Is Available'
                },
                {
                    property: 'og:title',
                    content: 'Countries tapabet Is Available'
                },
                {
                    property: 'og:url',
                    content: 'https://tapabet.co.ke/countries'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/profile',
        name: 'setting',
        component: Setting,
        meta: {
            title: 'My Account | tapabet | Best online sport betting |Freebet',
            metaTags: [
                {
                    name: 'description',
                    content: 'My tapabet account - best online sport betting in africa where you can win a lot of prizes from our live bets,multi bets, daily jackpot and many more.'
                },
                {
                    name: 'keywords',
                    content: 'Freebet, best online sports betting, online games,live bets, bets , odds, sports betting'
                }
            ]
        }
    },
    {
        path: '/my-bets',
        name: 'bets',
        component: Bets,
        meta: {
            title: 'tapabet - My Bets',
            metaTags: [
                {
                    name: 'description',
                    content: 'View all your bets, pending bets, resulted bets'
                }
            ]
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        meta: {
            title: 'tapabet Terms & Conditions | Responsible Gaming |Sports betting: rules',
            metaTags: [
                {
                    name: 'description',
                    content: 'These are the tapabet betting terms and conditions (Sports betting: rules)on how to place your bet while you follow favorite sports in the world\'s top League.'
                },
                {
                    name: 'keywords',
                    content: 'Terms & conditions, Betting, winner, 1st half, Both team to score, half time , full time, sport betting,live sports betting,online betting,bet and win,online football,bet online,soccer bets,champions league,barclays premier league,football betting site'
                }
            ]
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            title: 'tapabet FAQs | Bet on sports online and win big| bet and win',
            metaTags: [
                {
                    name: 'description',
                    content: 'Here are detailed answers to the most frequently asked question about tapabet  sports betting online .'
                },
                {
                    name: 'keywords',
                    content: 'FAQs, Betting online, online sports betting FAQs, tapabet account,tapabet paybill number, Mpesa,bet slip, place bet.'
                }
            ]
        }
    },
    {
        path: '/howto',
        name: 'help',
        component: Help,
        meta: {
            title: 'tapabet Help | How To Place a Bet | Online Sports Betting ',
            metaTags: [
                {
                    name: 'description',
                    content: 'Not sure how to start your online sports betting? Here is how to start your online betting game, sign up to join the player community started with tapabet.'
                },
                {
                    name: 'keywords',
                    content: 'football online , betting , single bet, odds , bet id, sport betting,scores, Get in the game'
                }
            ]
        }
    },
    {
        path: '/responsible-gaming',
        name: 'responsible',
        component: Responsible
    },
    {
        path: '/reset/:msisdn/:code',
        name: 'reset',
        component: Reset,
    },
    {
        path: '/booklet',
        name: 'booklet',
        component: Booklet,
    },
    {
        path: '/jackpot-games',
        name: 'jackpot',
        component: Jackpot,
    },
    {
        path: '/casino',
        name: 'casino',
        component: Casino,
    },
    {
        path: '/virtuals',
        name: 'virtuals',
        component: Virtuals,
    },
    {
        path: '/casino/live',
        name: 'livecasino',
        component: LiveCasino,
    },
    {
        path: '/casino-games',
        name: 'casinogames',
        component: CasinoGames,
    },
    {
        path: '/xgames',
        name: 'xgames',
        component: XGames,
    },
    {
        path: '/rocketman',
        name: 'rocketman',
        component: Rocketman,
    },
    {
        path: '/endorphina',
        name: 'endorphina',
        component: Endorphina,
    },
    {
        path: '/jetsafi',
        name: 'jetsafi',
        component: Jetsafi,
    },
    {
        path: '/endorphina-games',
        name: 'endorphinagames',
        component: EndorphinaGames,
    },
    {
        path: '*',
        name: '404',
        component: Home,
        meta: {
            title: 'tapabet.co.ke |  Your Best sports betting site| Fast play Fast win',
            metaTags: [
                {
                    name: 'title',
                    content: 'tapabet.co.ke |  Your Best sports betting site| Fast play Fast win'
                },
                {
                    name: 'description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    name: 'keywords',
                    content: 'tapabet, online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:description',
                    content: 'tapabet the leading international brand with over 14 years of experience and a strong passion for sports.'
                },
                {
                    property: 'twitter:title',
                    content: 'tapabet.co.ke |  Your Best sports betting site| Fast play Fast win'
                },
                {
                    property: 'og:title',
                    content: 'tapabet.co.ke |  Your Best sports betting site| Fast play Fast win'
                },
                {
                    property: 'og:url',
                    content: 'https://tapabet.co.ke'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
];